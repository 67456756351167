<template>
    <div class="touchpoint-map-head">
        <a target="_blank"
           @click.prevent="handleLogoClick"
           href="#"
           class="touchpoint-map-head__logo-link">
            <div class="logo"></div>
        </a>
        <div class="touchpoint-map-head__title">
            {{ market.title }}
        </div>

        <div class="touchpoint-map-head__desktop">
            <LanguageSwitch v-if="languageSwitchEnabled"/>

            <div v-if="privacy || imprint" class="touchpoint-map-head__forwarding">
                <a v-if=" imprint"
                   target="_blank"
                   :href="imprint"
                   class="touchpoint-map-head__forwarding-links">
                    Impressum
                </a>
                <a v-if="privacy"
                   target="_blank"
                   :href="privacy"
                   class="touchpoint-map-head__forwarding-links">
                    Datenschutz
                </a>
            </div>
        </div>

        <div class="touchpoint-map-head__dropdown" v-on:click="toggle" v-bind:class="{'open' : dropdown.open}">
            <a class="">
                <i class="menu__icon fa fa-bars"></i>
            </a>

            <ul class="dropdown-menu">
                <li class="dropdown-menu__item" v-if="languageSwitchEnabled">
                    <LanguageSwitch/>
                </li>

                <li class="dropdown-menu__item" v-if=" imprint">
                    <a target="_blank"
                       :href="imprint"
                       class="touchpoint-map-head__forwarding-links">
                        Impressum
                    </a>
                </li>

                <li class="dropdown-menu__item" v-if="privacy">
                    <a target="_blank"
                       :href="privacy"
                       class="touchpoint-map-head__forwarding-links">
                        Datenschutz
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="appIsCloseable"
             class="touchpoint-map-head__actions touchpoint-map-head__close-button">
            <a target="_self"
               :href="logoLink"
               class="touchpoint-map-head__link">
                <action-button icon="times"/>
            </a>
        </div>

    </div>
</template>
<script>

import ActionButton from '@/components/ActionButton';
import CacheImg from '@/components/CacheImg';
import LanguageSwitch from '@/components/LanguageSwitch';

export default {
    name: 'TouchpointMapHead',
    components: {
        ActionButton,
        CacheImg,
        LanguageSwitch,
    },
    props: {
        market: {
            default: null,
        },
    },
    data() {
        return {
            dropdown: {open: false},
        };
    },
    methods: {
        handleLogoClick() {
            if (process.env['VUE_APP_FAIR'] === 'true') {
                return;
            }
            if (process.env['VUE_APP_LOGO_EXTERNAL'] === 'true') {
                window.open(process.env['VUE_APP_LOGO_LINK'], '_blank');
            } else {
                this.$router.push('/');
            }
        },


        toggle: function () {
            this.dropdown.open = !this.dropdown.open;
        },

    },
    computed: {
        languageSwitchEnabled() {
            return process.env['VUE_APP_LANGUAGE_SWITCHER'] !== 'false';
        },
        imprint() {
            return process.env['VUE_APP_IMPRINT'];
        },
        privacy() {
            return process.env['VUE_APP_PRIVACY'];
        },
        appIsCloseable() {
            return process.env['VUE_APP_CLOSEABLE'] === 'true';
        },
        logoLink() {
            return process.env['VUE_APP_LOGO_LINK'];
        },
    },
};
</script>
