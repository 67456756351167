<template>
    <div class="media-slider" v-if="slides.length > 0">
        <div class="media-slider__slide">
            <cache-img v-if="slides[activeSlide].type === 'touchpoint_image'"
                       :path="slides[activeSlide].url" />
            <cache-video v-if="slides[activeSlide].type === 'touchpoint_video'"
                         :path="slides[activeSlide].url" />
        </div>
        <div class="media-slider__actions"
             v-if="slides.length > 1">
            <button @click.prevent="prev"
                    class="media-slider__action media-slider__action--prev"></button>
            <button @click.prevent="next"
                    class="media-slider__action media-slider__action--next"></button>
        </div>
    </div>
</template>
<script>
import CacheImg from '@/components/CacheImg';
import CacheVideo from '@/components/CacheVideo';

export default {
    name: 'MediaSlider',
    components: {
        CacheImg,
        CacheVideo,
    },
    props: {
        slides: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            activeSlide: 0,
        };
    },
    methods: {
        prev() {
            if (this.activeSlide <= 0) {
                this.activeSlide = this.slides.length - 1;
            } else {
                this.activeSlide--;
            }
        },
        next() {
            if (this.activeSlide >= this.slides.length - 1) {
                this.activeSlide = 0;
            } else {
                this.activeSlide++;
            }
        },
    },
};
</script>