<template>
    <div class="touchpoint-map"
         :class="{'touchpoint-map--fit': !scrollable}"
         ref="map"
         v-drag-scroll="scrollable">
        <div class="touchpoint-map__inner">
            <div class="touchpoint-map__bg"
                 ref="bg">
                <cache-img v-if="background"
                           :path="background"
                           @ready="centerMap" />
            </div>
            <!-- LEVEL 1 -->
            <div class="touchpoint-map__pins"
                 v-if="scrollable">
                <transition-group name="touchpoints"
                                  appear>
                    <touchpoint v-for="(touchpoint, index) in touchpoints"
                                :key="`touchpoint-${index}`"
                                :no-link="false"
                                ref="touchpoint"
                                :touchpoint="touchpoint"
                    />
                </transition-group>
            </div>
            <!-- LEVEL 2 -->
            <div class="touchpoint-map__pins"
                 v-else>
                <transition-group name="touchpoints"
                                  appear>
                    <template v-for="(touchpoint, index) in touchpoints" :key="`touchpoint-${index}`">
                        <touchpoint v-if="touchpoint.type === 'external'"
                                    :small="true"
                                    :touchpoint="touchpoint" />
                        <touchpoint v-else
                                    :no-title="touchpointNoTitle"
                                    :no-link="true"
                                    :touchpoint="touchpoint"
                                    :small="true"
                                    :fade-out="selected && selected.title !== touchpoint.title"
                                    @click.prevent="bubbleTouchpoint(touchpoint)"
                        />
                    </template>

                </transition-group>
            </div>
        </div>
    </div>
</template>
<script>
import CacheImg from '@/components/CacheImg';
import Touchpoint from '@/components/Touchpoint';

export default {
    name: 'TouchpointMap',
    data() {
        return {
            touchpointNoTitle: true,
        }
    },
    props: {
        value: {}, // v-model
        selected: {},
        scrollable: {
            type: Boolean,
            default: true,
        },
        touchpoints: {
            type: Array,
            default: [],
        },
        background: {
            type: String,
            default: '',
        },
    },
    components: {
        CacheImg,
        Touchpoint,
    },
    methods: {
        centerMap() {
            if (process.env.VUE_APP_MAP_CENTERED === 'true') {
                let map = this.$refs.map;
                let bg = this.$refs.bg;

                map.scrollLeft = bg.offsetWidth / 2 - map.offsetWidth / 2;
            }
        },
        bubbleTouchpoint(tp) {
            this.$emit('input', tp);
        },
        handleScroll() {
            const pins = this.$refs.touchpoint;
            const viewport = window.innerWidth;

            pins.forEach((pin) => {
                const pinWidth = pin.$el.offsetWidth;
                const pinLeft = pin.$el.getBoundingClientRect().left;
                const pinRight = pinLeft + pinWidth;

                if (pinLeft < 0 && pinRight < 0) {
                    // left outside
                    // console.log('pin ist links raus');
                } else if (pinLeft > viewport && pinRight > viewport) {
                    // console.log('pin ist rechts raus');
                } else {
                    // console.log('pin ist sichtbar');
                }
            });

        },
    },
    async mounted() {
        if (process.env.VUE_APP_TOUCHPOINT_SECOND_LEVEL_TITLE && process.env.VUE_APP_TOUCHPOINT_SECOND_LEVEL_TITLE  === 'true'){
            this.touchpointNoTitle = false;
        }
        // if (this.scrollable) {
        //     this.$refs.map.addEventListener('scroll', this.handleScroll);
        // }
    },
    beforeUnmount() {
        // if (this.scrollable) {
        //     this.$refs.map.removeEventListener('scroll', this.handleScroll);
        // }
    },
};
</script>
