<template>
    <div class="market-view">
        <touchpoint-map-head :market="market" v-if="market.flag !== false" />

        <touchpoint-map :touchpoints="market.routes"
                        :background="market.image"></touchpoint-map>


        <touchpoint-map-hint>
            Swipe to explore more touchpoints
        </touchpoint-map-hint>


        <router-view v-slot="{ Component }">
            <transition name="touchpoint-view"
                        appear>
                <component :is="Component" />
            </transition>
        </router-view>

    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import TouchpointMap from '@/components/TouchpointMap';
import TouchpointMapHead from '@/components/TouchpointMapHead';
import TouchpointMapHint from '@/components/TouchpointMapHint';

export default {
    name: 'MarketView',
    components: {
        TouchpointMap,
        TouchpointMapHead,
        TouchpointMapHint,
    },
    methods: {
        ...mapActions([
            'loadMarket',
            'setLocale',
            'setMarketIdentifier',
        ]),
        handleIdle() {
            this.$router.push({ name: 'market', params: { locale: this.active_locale, market: this.market.machine_name } });
        },
        resetTimer() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.handleIdle, Number(process.env['VUE_APP_IDLE_TIME']));
        },

    },
    computed: {
        ...mapState([
            'active_locale',
            'market',
        ]),
    },
    async mounted() {
        // set current locale on mount
        const locale = this?.$route?.params?.locale;
        if (locale) await this.setLocale(locale);

        // set current market on mount
        const market = this?.$route?.params?.market;
        await this.setMarketIdentifier(market);

        // load market
        await this.loadMarket();

        if (process.env['VUE_APP_IDLE']) { // idle timer
            window.onload = this.resetTimer;
            window.onmousemove = this.resetTimer;
            window.onmousedown = this.resetTimer;
            window.ontouchstart = this.resetTimer;
            window.ontouchmove = this.resetTimer;
            window.onclick = this.resetTimer;
            window.onkeydown = this.resetTimer;
            window.addEventListener('scroll', this.resetTimer, true);
        }
    },
};
</script>
