<template>
    <div class="cache-img">
        <transition mode="out-in" name="fade">
            <img v-if="loaded"
                 key="img"
                 :src="img.src"
                 @load="$emit('ready')"
                 class="cache-img__source" />
        </transition>
    </div>
</template>
<script>
import {loadResource} from '@/lib/DataLoader';

export default {
    name: 'CacheImage',
    emits: ['ready'],
    props: {
        path: {
            default: null,
        },
    },
    data() {
        return {
            img: {
                src: '',
            },
            loaded: false,
        };
    },
    methods: {
        async loadImg(url) {
            this.img = new Image();
            this.img.src = url;
            this.img.onload = () => {
                this.loaded = true;
            }
        },
        async resourceToImg() {
            const resource = await loadResource(this.path);
            const url = URL.createObjectURL(resource);
            await this.loadImg(url);
        }
    },
    watch: {
        async path() {
            this.loaded = false;
            await this.resourceToImg();
        },
    },
    async mounted() {
        await this.resourceToImg();
    },
};
</script>
