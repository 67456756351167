<template>
    <template v-if="loading"></template>
    <template v-else>
        <router-view v-slot="{ Component }">
            <transition mode="out-in" name="fade" appear>
                <component :is="Component" />
            </transition>
        </router-view>
    </template>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'App',
    data() {
        return {
            loading: true,
            timer: 0,
        };
    },
    methods: {
        ...mapActions(['bootstrap']),

    },
    async mounted() {
        this.$nextTick(async () => {
            await this.bootstrap();
            this.loading = false;
        });
    },
};
</script>